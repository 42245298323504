import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'lodash';
import { Key, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Attachment,
  Company,
  DurationType,
  NoticeAndOrder,
  NoticeAndOrderStatus,
  NoticesAndOrdersSort,
  NoticesAndOrdersSortOn,
  SortOrder,
} from '../../../../__generated__/graphql';
import {
  Export,
  FilePDF,
  Icon,
  Minus,
  Pencil,
  SelectDropdownIcon,
} from '../../../../assets/svg';
import axiosInstance from '../../../../common/axiosInstance';
import {
  AI_TOOLTIP_MESSAGE,
  color,
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  DURATION_TYPE,
  EMPTY_STATE,
  LIMIT,
  MESSAGE,
  NOTICE_ORDER_STATUS_OPTIONS,
  NOTICE_ORDER_TYPE_OPTIONS,
} from '../../../../common/constants';
import useQueryValue from '../../../../common/hooks/useQueryValue';
import {
  disabledYear,
  downloadCsv,
  formValidatorRules,
} from '../../../../common/utils';
import CommonPagination from '../../../../components/CommonPagination';
import CommonSearch from '../../../../components/CommonSearch';
import TableComponent from '../../../../components/CommonTable';
import StatusTag from '../../../../components/CommonTag';
import InfiniteSelect from '../../../../components/InfiniteSelect';
import AttachmentModal from '../../../../components/modals/AttachmentModal';
import CommonModal from '../../../../components/modals/CommonModal';
import useRouter from '../../../../hooks/useRouter';
import {
  SelectedRowKeysState,
  UpdateStatus,
} from '../../../../types/common.type';
import { COMPANIES, COMPANY } from '../../companies/graphql/Queries';
import { UPDATE_NOTICE_ORDER_STATUS_BULK } from '../graphql/Mutations';
import { NOTICES_ORDERS_LIST } from '../graphql/Queries';
import { PROPS_TYPE } from '../Notice';
const { RangePicker } = DatePicker;
const { required } = formValidatorRules;

const initialFilters = {
  search: '',
  skip: 0,
  limit: LIMIT,
};

const fieldToParams: Record<string, string> = {
  issuanceDate: COMMON_QUERY_PARAMS.SORT_ISSUANCE_DATE,
  dueDate: COMMON_QUERY_PARAMS.SORT_DUE_DATE,
  status: COMMON_QUERY_PARAMS.SORT_STATUS,
};

const NoticeOrder = ({ count, setCount }: PROPS_TYPE) => {
  const [filterForm] = Form.useForm();
  const [statusForm] = Form?.useForm();
  const { navigate } = useRouter();
  const { params } = useRouter();
  const { id } = params;
  const [searchParams] = useSearchParams();
  const urlDataObj = useQueryValue();
  const currentPage = urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
    ? Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE])
    : 1;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [companiesData, setCompaniesData] = useState<Company | null>(null);
  const companyId = urlDataObj?.[COMMON_QUERY_PARAMS.COMPANY_ID];
  const gstNumber =
    searchParams.get(COMMON_QUERY_PARAMS.CLIENT_LABEL) ??
    companiesData?.gstNumber;
  const tradeName =
    searchParams.get(COMMON_QUERY_PARAMS.NAME) ?? companiesData?.tradeName;
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeysState>(
    {},
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [attachments, setAttachment] = useState<Attachment[]>([]);
  const [isTableSorterChange, setIsTableSorterChange] =
    useState<boolean>(false);
  const updateBulkStatus = Form.useWatch('statusForm', statusForm);
  const askAI = process.env.REACT_APP_ASK_AI_REDIRECT_URL!;
  const totalSelectedRows = Object?.values(selectedRowKeys)?.flat();
  const [search, setSearch] = useState<string>('');
  const cancelModal = () => {
    setIsModalOpen(false);
    statusForm.resetFields();
  };

  const initialValue = {
    filters: {
      companyId:
        (id || companyId) && (gstNumber || tradeName)
          ? {
              value: id,
              label:
                gstNumber && !tradeName
                  ? gstNumber
                  : [gstNumber, tradeName].filter(Boolean).join(' | ') || null,
            }
          : null,
      duration:
        (urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] as DurationType) || null,
      ids: [],
      type: urlDataObj?.[COMMON_QUERY_PARAMS.NOTICE_TYPE] || null,
      ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
        DurationType.Custom && {
        period: [
          urlDataObj?.[COMMON_QUERY_PARAMS.START]
            ? dayjs(
                urlDataObj?.[COMMON_QUERY_PARAMS.START],
                defaultDateFormat,
              ).startOf('day')
            : '',
          urlDataObj?.[COMMON_QUERY_PARAMS.END]
            ? dayjs(
                urlDataObj?.[COMMON_QUERY_PARAMS.END],
                defaultDateFormat,
              ).endOf('day')
            : '',
        ],
      }),
      limit: Number(urlDataObj?.[COMMON_QUERY_PARAMS.LIMIT]) || LIMIT,
      skip: urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
        ? (Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]) - 1) * LIMIT
        : 0,
      status:
        (urlDataObj?.[COMMON_QUERY_PARAMS.STATUS] as NoticeAndOrderStatus) ||
        null,
      search: '',
    },
  };
  const filters = initialValue.filters;
  filterForm.setFieldsValue({ filters: filters });

  const prepareQueryVariableFromUrl = useMemo(() => {
    let prepareSorterFromURL: {
      sortBy: SortOrder;
      sortOn: NoticesAndOrdersSortOn;
    }[] = [];
    Object.keys(fieldToParams).forEach((item) => {
      if (searchParams.has(fieldToParams?.[item as string])) {
        const value = searchParams.get(fieldToParams?.[item as string]);
        prepareSorterFromURL.push({
          sortBy: value as SortOrder,
          sortOn: item as NoticesAndOrdersSortOn,
        });
      }
    });
    if (prepareSorterFromURL.length === 0 && !isTableSorterChange) {
      prepareSorterFromURL = [
        { sortBy: SortOrder.Desc, sortOn: NoticesAndOrdersSortOn.DueDate },
      ];
    }

    if (isTableSorterChange && prepareSorterFromURL.length === 0) {
      prepareSorterFromURL = [
        { sortBy: SortOrder.Desc, sortOn: NoticesAndOrdersSortOn.CreatedAt },
      ];
    }

    return {
      filter: {
        companyId: companyId || id || null,
        duration:
          (urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] as DurationType) || null,
        ids: [],
        ...(urlDataObj?.[COMMON_QUERY_PARAMS.IS_ISSUED_ON] && {
          isIssuedOn: !urlDataObj?.[COMMON_QUERY_PARAMS.IS_ISSUED_ON],
        }),
        type: urlDataObj?.[COMMON_QUERY_PARAMS.NOTICE_TYPE] || null,
        ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
          DurationType.Custom && {
          period: {
            start: urlDataObj?.[COMMON_QUERY_PARAMS.START]
              ? dayjs(
                  urlDataObj?.[COMMON_QUERY_PARAMS.START],
                  defaultDateFormat,
                ).startOf('day')
              : '',
            end: urlDataObj?.[COMMON_QUERY_PARAMS.END]
              ? dayjs(
                  urlDataObj?.[COMMON_QUERY_PARAMS.END],
                  defaultDateFormat,
                ).endOf('day')
              : '',
          },
        }),
        limit: Number(urlDataObj?.[COMMON_QUERY_PARAMS.LIMIT]) || LIMIT,
        skip: urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
          ? (Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]) - 1) * LIMIT
          : 0,
        status:
          (urlDataObj?.[COMMON_QUERY_PARAMS.STATUS] as NoticeAndOrderStatus) ||
          null,
        search: search || '',
      },
      sort: prepareSorterFromURL as NoticesAndOrdersSort[],
    };
  }, [location.search, isTableSorterChange, search]);

  const [getCompanies] = useLazyQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (id) {
      getCompanies({
        variables: {
          where: { id: id },
        },
        onCompleted: (response) => {
          if (response) {
            setCompaniesData(response?.company as Company);
            filterForm.setFieldsValue({
              filters: {
                companyId: {
                  label: `${response?.company?.gstNumber ?? ''} | ${response?.company?.tradeName ?? ''}`,
                  value: id,
                },
              },
            });
          }
        },
      });
    }
  }, [id]);

  const handlePagination = (page: number) => {
    searchParams.set(COMMON_QUERY_PARAMS.PAGE, page.toString());
    searchParams.set(COMMON_QUERY_PARAMS.LIMIT, LIMIT.toString());
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const resetPagination = () => {
    searchParams.delete(COMMON_QUERY_PARAMS.PAGE);
    searchParams.delete(COMMON_QUERY_PARAMS.LIMIT);
  };

  /* any used due to type incompatibility */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateRangeNavigation = (date: any) => {
    if (date && date.length > 0) {
      const startDate = dayjs(date[0]).format(defaultDateFormat);
      const endDate = dayjs(date[1]).format(defaultDateFormat);
      searchParams.set(COMMON_QUERY_PARAMS.START, startDate);
      searchParams.set(COMMON_QUERY_PARAMS.END, endDate);
      searchParams.delete(COMMON_QUERY_PARAMS.IS_ISSUED_ON);
    } else {
      if (
        searchParams.has(COMMON_QUERY_PARAMS.START) ||
        searchParams.has(COMMON_QUERY_PARAMS.END) ||
        searchParams.has(COMMON_QUERY_PARAMS.IS_ISSUED_ON)
      ) {
        searchParams.delete(COMMON_QUERY_PARAMS.START);
        searchParams.delete(COMMON_QUERY_PARAMS.END);
        searchParams.delete(COMMON_QUERY_PARAMS.IS_ISSUED_ON);
      }
    }
    resetPagination();
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const handleClear = () => {
    navigate(`${location?.pathname}`, {
      replace: true,
    });
  };

  const {
    data: { noticesAndOrders = {} } = {},
    loading,
    refetch,
  } = useQuery(NOTICES_ORDERS_LIST, {
    variables: prepareQueryVariableFromUrl,
    fetchPolicy: 'network-only',
    onCompleted: ({ noticesAndOrders }) => {
      setCount({ ...count, noticeCount: noticesAndOrders?.count ?? 0 });
    },
    onError() {},
  });

  const rowSelection: TableProps<NoticeAndOrder>['rowSelection'] = {
    selectedRowKeys: selectedRowKeys?.[currentPage],
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys((prev) => ({
        ...prev,
        [currentPage]: newSelectedRowKeys,
      }));
    },
    ...(selectedRowKeys[currentPage]?.length && {
      columnTitle: () => (
        <span
          className="d-flex pointer"
          onClick={() => {
            setSelectedRowKeys((prev) => ({
              ...prev,
              [currentPage]: [],
            }));
          }}
        >
          <Minus />
        </span>
      ),
    }),
  };

  const handleFieldsNavigation = (
    fieldType: string,
    value: string | null,
    additionalParams?: Record<string, string | null>,
  ) => {
    resetPagination();
    if (value) {
      searchParams.set(fieldType, value as string);
    } else {
      if (searchParams.has(fieldType)) {
        searchParams.delete(fieldType);
      }
    }
    if (additionalParams) {
      Object.entries(additionalParams).forEach(([key, val]) => {
        if (val) {
          searchParams.set(key, val);
        } else if (searchParams.has(key)) {
          searchParams.delete(key);
        }
      });
    }
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const exportCsv = async () => {
    const response = await axiosInstance.post('/v1/notice-order/export-csv', {
      filter: {
        ...omit(prepareQueryVariableFromUrl.filter, ['limit', 'skip']),
        ids: totalSelectedRows,
      },
      sort: prepareQueryVariableFromUrl?.sort,
    });
    downloadCsv(response);
    setSelectedRowKeys({});
  };

  const handleDurationNavigation = (e: string) => {
    if (
      searchParams.has(COMMON_QUERY_PARAMS.START) ||
      searchParams.has(COMMON_QUERY_PARAMS.END)
    ) {
      searchParams.delete(COMMON_QUERY_PARAMS.START);
      searchParams.delete(COMMON_QUERY_PARAMS.END);
    }
    resetPagination();
    handleFieldsNavigation(COMMON_QUERY_PARAMS.DURATION, e);
  };

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_NOTICE_ORDER_STATUS_BULK,
    {
      onCompleted: () => {
        refetch(prepareQueryVariableFromUrl);
        statusForm.resetFields();
        setIsModalOpen(false);
        setSelectedRowKeys({});
        resetPagination();
        navigate(`${location?.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      },
    },
  );

  const updateStateForm = (value: UpdateStatus) => {
    updateStatus({
      variables: {
        where: {
          ids: value?.statusForm?.ids,
        },
        data: {
          status: value?.statusForm?.status,
          remarks: value?.statusForm?.remarks ?? '',
        },
      },
    });
  };

  const handleTableChange: TableProps['onChange'] = (_, _filters, sorter) => {
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    Object.values(fieldToParams).forEach((item) => {
      if (searchParams.has(item as string)) {
        searchParams.delete(item);
      }
    });
    if (sorterAry && Array.isArray(sorterAry)) {
      if (!isTableSorterChange) {
        setIsTableSorterChange(true);
        sorterAry.splice(0, 1);
      }
      sorterAry.forEach((item) => {
        const field = item.field as keyof typeof fieldToParams;
        if (item?.order) {
          searchParams.set(
            fieldToParams[field],
            item?.order === 'descend' ? SortOrder.Desc : SortOrder.Asc,
          );
        }
      });
      navigate(`${location?.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  };

  const getSorter = (fieldName: string) => {
    let order = null;
    if (prepareQueryVariableFromUrl?.sort?.length > 0) {
      prepareQueryVariableFromUrl?.sort?.find((item) => {
        if (item?.sortOn === fieldName) {
          order = item?.sortBy === SortOrder.Asc ? 'ascend' : 'descend';
        }
      });
    }
    return order;
  };

  const handleAttachment = (item: NoticeAndOrder) => {
    if ((item?.attachments?.length ?? 0) > 0) {
      setOpenModal(true);
      setAttachment(item?.attachments as Attachment[]);
    }
  };

  const columns: ColumnType<NoticeAndOrder>[] = [
    {
      title: 'GSTIN',
      dataIndex: 'gstNumber',
      key: '_id',
      fixed: 'left',
      render: (_, record) => {
        return record?.company?.gstNumber ?? '-';
      },
    },
    {
      title: 'Trade Name',
      dataIndex: 'tradeName',
      key: 'tradeName',
      fixed: 'left',
      render: (_, record) => {
        return record?.company?.tradeName ?? '-';
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => text || '-',
    },
    {
      title: 'Reference ID',
      dataIndex: 'refId',
      key: 'refId',
      render: (text) => text || '-',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text || '-',
    },
    {
      title: 'Issuance Date',
      dataIndex: 'issuanceDate',
      key: 'issuanceDate',
      sorter: { multiple: 2 },
      sortOrder: getSorter('issuanceDate'),
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: { multiple: 1 },
      sortOrder: getSorter('dueDate'),
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: 'Amount of Demand',
      dataIndex: 'demandAmount',
      key: 'demandAmount',
      render: (text) => text ?? '-',
    },
    {
      title: 'Issued By',
      dataIndex: 'issuedBy',
      key: 'issuedBy',
      render: (text) => text || '-',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      render: (text) => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: 'right',
      key: 'status',
      sorter: { multiple: 3 },
      sortOrder: getSorter('status'),
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record) => {
        const pdf = record?.attachments?.[0]?.url;
        return (
          <Space>
            <span
              key="file"
              onClick={() => handleAttachment(record)}
              className={`${!pdf ? 'pointer-not-allowed' : 'pointer'}`}
            >
              <FilePDF {...(!pdf && { color: '#C6C6C6' })} />
            </span>
            <span
              key="edit"
              onClick={() => {
                setIsModalOpen(true);
                statusForm.setFieldsValue({
                  statusForm: {
                    status: record?.status,
                    ids: [record?._id],
                    remarks: record?.remarks,
                  },
                });
              }}
              className="pointer"
            >
              <Pencil color={color} />
            </span>
            <Tooltip placement="top" title={AI_TOOLTIP_MESSAGE}>
              <Button
                type="default"
                icon={<Icon color={color} />}
                className="d-flex align-center ask-ai-btn pointer p-0"
                onClick={() => window.open(askAI)}
              >
                Ask AI
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  return (
    <div className="full-width">
      <div className="button-container">
        <div className="left-buttons gap-16">
          <Button
            type="default"
            onClick={async () => {
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  ids: totalSelectedRows,
                },
              });
            }}
            disabled={!(totalSelectedRows.length > 0)}
          >
            Bulk Update Status
          </Button>
          <Button
            type="default"
            icon={<Export color="#04724D" />}
            onClick={() => exportCsv()}
          >
            Export To CSV
          </Button>
        </div>
        <div className="right-buttons gap-16">
          <CommonSearch
            handleChangeSearch={(val) => {
              setSearch(val);
            }}
            iconPlacement="right"
            allowClear
          />
        </div>
      </div>
      <Card className="table-card mt-16">
        <div className="d-flex flex-vertical gap-16">
          <Form
            form={filterForm}
            layout="vertical"
            initialValues={initialFilters}
            className="filter-form"
          >
            <div className="filters d-flex align-center gap-8">
              <Form.Item
                name={['filters', 'companyId']}
                label="Select Client"
                className="select"
              >
                <InfiniteSelect
                  query={COMPANIES}
                  fetchPolicy="network-only"
                  variableSelector={({ skip, limit, search }) => ({
                    filter: {
                      skip,
                      limit,
                      search,
                      withTradeName: false,
                    },
                    sort: {
                      sortBy: SortOrder.Desc,
                    },
                  })}
                  dataSelector={({ companies }) => {
                    return (
                      companies?.data?.map((item) => {
                        const values =
                          [item?.gstNumber, item?.tradeName].join(' | ') ?? '';
                        return {
                          label: (
                            <Tooltip title={values} placement="top">
                              {values}
                            </Tooltip>
                          ),
                          value: item?._id ?? '',
                        };
                      }) ?? []
                    );
                  }}
                  countSelector={({ companies }) => companies?.count ?? 0}
                  allowSearch
                  placeholder="GSTIN, Tradename"
                  allowClear
                  disabled={!!id}
                  /* any used to onchange value type incompatibility */
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => {
                    const gstNumber = e?.label?.props?.title?.split(' | ')[1];
                    const tradeName = e?.label?.props?.title?.split(' | ')[0];
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.COMPANY_ID,
                      e && (e.value as string),
                      {
                        [COMMON_QUERY_PARAMS.NAME]: gstNumber,
                        [COMMON_QUERY_PARAMS.CLIENT_LABEL]: tradeName,
                      },
                    ),
                      setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'type']}
                label="Type"
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={NOTICE_ORDER_TYPE_OPTIONS}
                  onChange={(e: string) => {
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.NOTICE_TYPE,
                      e ?? null,
                    ),
                      setSelectedRowKeys([]);
                  }}
                  allowClear
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'status']}
                label="Status"
                className="select"
              >
                <Select
                  placeholder="All"
                  options={NOTICE_ORDER_STATUS_OPTIONS}
                  allowClear
                  showSearch
                  suffixIcon={<SelectDropdownIcon />}
                  onChange={(e: string) => {
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.STATUS,
                      e ?? null,
                    ),
                      setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'duration']}
                label="Duration"
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={DURATION_TYPE}
                  onChange={(e: string) => {
                    handleDurationNavigation(e), setSelectedRowKeys([]);
                  }}
                  allowClear
                />
              </Form.Item>
              {filters?.duration === DurationType.Custom && (
                <Form.Item
                  name={['filters', 'period']}
                  label="Period"
                  className="select"
                >
                  <RangePicker
                    allowClear
                    onChange={(date) => {
                      handleDateRangeNavigation(date), setSelectedRowKeys([]);
                    }}
                    format={defaultDateFormat}
                    disabledDate={disabledYear}
                  />
                </Form.Item>
              )}
              <Form.Item className="d-flex align-end align-self-end">
                <Button
                  type="link"
                  onClick={handleClear}
                  disabled={
                    !(
                      (id ? !filters?.companyId : filters?.companyId) ||
                      filters.status ||
                      filters.type ||
                      filters.duration ||
                      filters.search
                    )
                  }
                >
                  Clear All
                </Button>
              </Form.Item>
            </div>
          </Form>
          <TableComponent
            columns={columns}
            dataSource={noticesAndOrders?.data as NoticeAndOrder[]}
            pagination={false}
            rowSelection={rowSelection}
            rowKey="_id"
            scroll={{ x: 'max-content' }}
            locale={EMPTY_STATE}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
        {noticesAndOrders?.count ? (
          <CommonPagination
            count={noticesAndOrders?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStateForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label="Status"
              name={['statusForm', 'status']}
              rules={[
                { ...required, message: MESSAGE?.required, whitespace: true },
              ]}
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                options={NOTICE_ORDER_STATUS_OPTIONS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item
              label="Remark"
              name={['statusForm', 'remarks']}
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Remark" name={['statusForm', 'ids']} hidden>
              <Select suffixIcon={<SelectDropdownIcon />} mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={updateStatusLoading}
              disabled={isEmpty(updateBulkStatus?.status)}
            >
              Update
            </Button>
          </Form>
        </div>
      </CommonModal>
      <AttachmentModal
        open={openModal}
        title="Attachments"
        closable={true}
        onCancel={() => setOpenModal(false)}
        attachments={attachments}
      />
    </div>
  );
};

export default NoticeOrder;
