import { useQuery } from '@apollo/client';
import { Button, Card, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import {
  DurationType,
  EProceeding,
  EProceedingSortOn,
  EProceedingStatus,
  IncomeTaxForm,
  Itr,
  SortOrder,
} from '../../../__generated__/graphql';
import {
  ActiveBox,
  Calendar,
  Files,
  HourGlassLaw,
  Pen,
  RegularCalendar,
} from '../../../assets/svg';
import {
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  EMPTY_STATE,
  MIN_LIMIT,
  ROUTES,
  ZERO_PLACEHOLDER_TEXT,
} from '../../../common/constants';
import TableComponent from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import StatisticCard from '../../../components/StatiscticCard';
import useRouter from '../../../hooks/useRouter';
import { ASSESSEE_STATES } from '../dashboard/graphql/Queries';
import EProceedingDetail from './component/EProceeding';
import JurisdictionDetails from './component/JurisdictionDetails';
import ProfileCard from './component/PanProfile';
import {
  ASSESSEE,
  E_PROCEEDING,
  INCOME_TAX_OTHER_FORM,
  INCOME_TAX_RETURN_FORM,
} from './graphql/Queries';
const { Text } = Typography;

const PanDashboard = () => {
  const { navigate, params } = useRouter();
  const { id } = params;

  const { data: assessee, loading } = useQuery(ASSESSEE, {
    skip: !id,
    fetchPolicy: 'network-only',
    onError() {},
    variables: { where: { id: id } },
  });

  const username = assessee?.assessee?.username;
  const nameParam = assessee?.assessee?.name;

  const { data: { assesseeStats } = {}, loading: loadStats } = useQuery(
    ASSESSEE_STATES,
    {
      skip: !id,
      fetchPolicy: 'network-only',
      onError() {},
      variables: { where: { id: id } },
    },
  );

  const { data: { eProceedings } = {}, loading: loadEProceedings } = useQuery(
    E_PROCEEDING,
    {
      skip: !id,
      fetchPolicy: 'network-only',
      onError() {},
      variables: {
        filter: { limit: MIN_LIMIT, assesseeId: id },
        sort: {
          sortBy: SortOrder?.Desc,
          sortOn: EProceedingSortOn?.ResponseDueDate,
        },
      },
    },
  );

  const { data: { itrList } = {}, loading: loadItrList } = useQuery(
    INCOME_TAX_RETURN_FORM,
    {
      skip: !id,
      fetchPolicy: 'network-only',
      onError() {},
      variables: {
        filter: { limit: MIN_LIMIT, assesseeId: id },
        sort: { sortBy: SortOrder?.Desc },
      },
    },
  );

  const { data: { itfList } = {}, loading: loadItfList } = useQuery(
    INCOME_TAX_OTHER_FORM,
    {
      skip: !id,
      fetchPolicy: 'network-only',
      onError() {},
      variables: {
        filter: { limit: MIN_LIMIT, assesseeId: id },
        sort: { sortBy: SortOrder?.Desc },
      },
    },
  );

  const ItrColumns: ColumnType<Itr>[] = [
    {
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Assessment Year',
      dataIndex: 'assessmentYear',
      key: 'assessmentYear',
      render: (text) => text ?? '-',
    },
    {
      title: 'Acknowledgement Number',
      dataIndex: 'acknowledgmentNo',
      key: 'acknowledgmentNo',
      render: (text) => text ?? '-',
    },
    {
      title: 'Filing Date',
      dataIndex: 'filingDate',
      key: 'filingDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
  ];

  const ItfColumns: ColumnType<IncomeTaxForm>[] = [
    {
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Assessment Year',
      dataIndex: 'assessmentYear',
      key: 'assessmentYear',
      render: (text) => text ?? '-',
    },
    {
      title: 'Acknowledgement Number',
      dataIndex: 'acknowledgmentNo',
      key: 'acknowledgmentNo',
      render: (text) => text ?? '-',
    },
    {
      title: 'Filing Date',
      dataIndex: 'filingDate',
      key: 'filingDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
  ];

  const load =
    loadEProceedings || loadItfList || loadItrList || loadStats || loading;

  return (
    <div className="container">
      <LoaderComponent spinning={load}>
        <div className="mt-16 mb-16 d-flex justify-between gap-32">
          <MyBreadcrumb
            username={username ?? (assessee?.assessee?.username || '')}
            assesseeId={assessee?.assessee?._id || ''}
          />
          <Text className="title">
            {username} | {assessee?.assessee?.name}
          </Text>
        </div>

        <ProfileCard title="Pan Profile" data={assessee?.assessee} />
        <JurisdictionDetails
          title="Jurisdiction Details"
          details={assessee?.assessee?.jurisdictionDetails}
        />

        <Card className="mb-24">
          <div className="statistic-card">
            <span
              onClick={() => {
                const prepareQuery = {
                  [COMMON_QUERY_PARAMS.DURATION]: DurationType.All,
                  [COMMON_QUERY_PARAMS.CLIENT_LABEL]: username ?? '',
                  [COMMON_QUERY_PARAMS.CLIENT]: id ?? '',
                  [COMMON_QUERY_PARAMS.NAME]: nameParam ?? '',
                };
                navigate(
                  `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(prepareQuery).toString()}`,
                );
              }}
            >
              <StatisticCard
                icon={<Pen color="#7A2976" />}
                label="All Notices"
                value={assesseeStats?.allNotices || ZERO_PLACEHOLDER_TEXT}
              />
            </span>
            <span
              onClick={() => {
                const prepareQuery = {
                  [COMMON_QUERY_PARAMS.DURATION]: DurationType.DueToday,
                  [COMMON_QUERY_PARAMS.CLIENT_LABEL]: username ?? '',
                  [COMMON_QUERY_PARAMS.CLIENT]: id ?? '',
                  [COMMON_QUERY_PARAMS.NAME]: nameParam ?? '',
                  [COMMON_QUERY_PARAMS.STATUS]: EProceedingStatus.Pending,
                };
                navigate(
                  `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(prepareQuery).toString()}`,
                );
              }}
            >
              <StatisticCard
                icon={<Calendar />}
                label="Due Today"
                value={assesseeStats?.dueToday || ZERO_PLACEHOLDER_TEXT}
              />
            </span>
            <span
              onClick={() => {
                const prepareQuery = {
                  [COMMON_QUERY_PARAMS.DURATION]: DurationType.Last_24Hours,
                  [COMMON_QUERY_PARAMS.CLIENT_LABEL]: username ?? '',
                  [COMMON_QUERY_PARAMS.CLIENT]: id ?? '',
                  [COMMON_QUERY_PARAMS.NAME]: nameParam ?? '',
                };
                navigate(
                  `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(prepareQuery).toString()}`,
                );
              }}
            >
              <StatisticCard
                icon={<HourGlassLaw />}
                label="Last 24 Hours"
                value={assesseeStats?.last24Hours || ZERO_PLACEHOLDER_TEXT}
              />
            </span>
            <span
              onClick={() => {
                const prepareQuery = {
                  [COMMON_QUERY_PARAMS.CLIENT_LABEL]: username ?? '',
                  [COMMON_QUERY_PARAMS.CLIENT]: id ?? '',
                  [COMMON_QUERY_PARAMS.NAME]: nameParam ?? '',
                  [COMMON_QUERY_PARAMS.STATUS]: EProceedingStatus.Pending,
                };
                navigate(
                  `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(prepareQuery).toString()}`,
                );
              }}
            >
              <StatisticCard
                icon={<Files />}
                label="Pending Notices"
                value={assesseeStats?.openNotices || ZERO_PLACEHOLDER_TEXT}
              />
            </span>
            <span
              onClick={() => {
                const prepareQuery = {
                  [COMMON_QUERY_PARAMS.DURATION]: DurationType.DueIn_7Days,
                  [COMMON_QUERY_PARAMS.CLIENT_LABEL]: username ?? '',
                  [COMMON_QUERY_PARAMS.CLIENT]: id ?? '',
                  [COMMON_QUERY_PARAMS.NAME]: nameParam ?? '',
                };
                navigate(
                  `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(prepareQuery).toString()}`,
                );
              }}
            >
              <StatisticCard
                icon={<ActiveBox />}
                label="7 days Due"
                value={assesseeStats?.oneWeekDue || ZERO_PLACEHOLDER_TEXT}
              />
            </span>
            <span
              onClick={() => {
                const prepareQuery = {
                  [COMMON_QUERY_PARAMS.DURATION]: DurationType.OverDues,
                  [COMMON_QUERY_PARAMS.CLIENT_LABEL]: username ?? '',
                  [COMMON_QUERY_PARAMS.CLIENT]: id ?? '',
                  [COMMON_QUERY_PARAMS.NAME]: nameParam ?? '',
                  [COMMON_QUERY_PARAMS.STATUS]: EProceedingStatus.Pending,
                };
                navigate(
                  `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(prepareQuery).toString()}`,
                );
              }}
            >
              <StatisticCard
                icon={<RegularCalendar />}
                label="OverDue"
                value={assesseeStats?.overDue || ZERO_PLACEHOLDER_TEXT}
              />
            </span>
          </div>
        </Card>
        <div className="d-flex gap-24 mb-32 full-width">
          <Card className="width-percent-50">
            <div className="d-flex flex-vertical gap-16">
              <div className="d-flex align-center justify-between">
                <Text className="heading">E-Proceeding</Text>
                <Button
                  type="link"
                  className="underline-btn"
                  onClick={() => {
                    const prepareQuery = {
                      [COMMON_QUERY_PARAMS.CLIENT_LABEL]: username ?? '',
                      [COMMON_QUERY_PARAMS.CLIENT]: id ?? '',
                      [COMMON_QUERY_PARAMS.NAME]: nameParam ?? '',
                    };
                    navigate(
                      `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(prepareQuery).toString()}`,
                    );
                  }}
                >
                  View all
                </Button>
              </div>
              <div className="notices-card">
                <EProceedingDetail
                  details={eProceedings?.data as EProceeding[]}
                />
              </div>
            </div>
          </Card>
          <Card className="width-percent-50">
            <div>
              <div className="d-flex align-center justify-between mb-16">
                <Text className="heading">Income Tax Return Forms</Text>
                <Button
                  type="link"
                  className="underline-btn"
                  onClick={() =>
                    navigate(
                      `${ROUTES?.FILED_RETURN}?client=${id}&clientLabel=${username}&name=${nameParam ?? ''}`,
                    )
                  }
                >
                  View all
                </Button>
              </div>
              <div>
                <TableComponent<Itr>
                  rowKey={(record) => record?._id as string}
                  columns={ItrColumns}
                  dataSource={itrList?.data as Itr[]}
                  loading={loadItrList}
                  className="mb-8"
                  locale={EMPTY_STATE}
                />
              </div>
              <div className="d-flex justify-between mb-16 align-center">
                <Text className="heading">Other Forms</Text>
                <Button
                  type="link"
                  className="underline-btn"
                  onClick={() =>
                    navigate(
                      `${ROUTES?.OTHER_FILED}?client=${id}&clientLabel=${username}&name=${nameParam ?? ''}`,
                    )
                  }
                >
                  View all
                </Button>
              </div>
              <div>
                <TableComponent<IncomeTaxForm>
                  rowKey={(record) => record?._id as string}
                  columns={ItfColumns}
                  dataSource={itfList?.data as IncomeTaxForm[]}
                  loading={loadItfList}
                  locale={EMPTY_STATE}
                />
              </div>
            </div>
          </Card>
        </div>
      </LoaderComponent>
    </div>
  );
};

export default PanDashboard;
