import { useLazyQuery } from '@apollo/client';
import { Card, Form, Select, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import {
  Company,
  InputMaybe,
  SortOrder,
  TrackReturnStatus,
  TrackReturnStatusFilter,
  TrackReturnStatusListResponse,
} from '../../../__generated__/graphql';
import { SelectDropdownIcon } from '../../../assets/svg';
import {
  defaultDateFormat,
  EMPTY_STATE,
  GST_START_YEAR,
  LIMIT,
  SELECT_CLIENT_MESSAGE,
} from '../../../common/constants';
import { generateYearOptions } from '../../../common/utils';
import TableComponent from '../../../components/CommonTable';
import InfiniteSelect from '../../../components/InfiniteSelect';
import LoaderComponent from '../../../components/LoaderComponent';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import { COMPANIES, COMPANY } from '../companies/graphql/Queries';
import { GET_TRACK_RETURN_STATUS } from './graphql/Queries';
const { Text } = Typography;

const initialFilters = {
  financialYear: null,
  companyId: null,
  skip: 0,
  limit: LIMIT,
};

const initialValue = {
  filters: {
    companyId: null,
    financialYear: null,
    duration: {
      end: null,
      start: null,
    },
  },
};

const TrackReturnsStatus = () => {
  const [filterForm] = Form.useForm();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<TrackReturnStatusListResponse | null>(null);
  const [companiesData, setCompaniesData] = useState<Company | null>(null);
  const filters = Form.useWatch('filters', filterForm) ?? initialValue.filters;
  const { params } = useRouter();
  const { id } = params;

  const filter: InputMaybe<TrackReturnStatusFilter> = useMemo(
    () => ({
      financialYear: filters?.financialYear,
      companyId: filters?.companyId?.value ?? id,
      skip: (currentPage - 1) * LIMIT,
      limit: filters.limit,
    }),
    [filters, id],
  );

  const [getTrackReturnStatus, { loading }] = useLazyQuery(
    GET_TRACK_RETURN_STATUS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: (response) => {
        if (response.trackReturnStatusList) {
          setData(response?.trackReturnStatusList);
        }
      },
    },
  );
  const [getCompanies] = useLazyQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (id) {
      getCompanies({
        variables: {
          where: { id: id },
        },
        onCompleted: (response) => {
          if (response) {
            setCompaniesData(response?.company as Company);
            filterForm.setFieldsValue({
              filters: {
                companyId: {
                  label: `${response?.company?.gstNumber ?? ''} | ${response?.company?.tradeName ?? ''}`,
                  value: id,
                },
              },
            });
          }
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (filter?.companyId) {
      getTrackReturnStatus({
        variables: {
          filter: filter,
        },
      });
    }
  }, [filter]);

  const columns: ColumnType<TrackReturnStatus>[] = [
    {
      title: 'Financial Year',
      dataIndex: 'financialYear',
      key: 'financialYear',
      render: (text) => text ?? '-',
    },
    {
      title: 'Period',
      dataIndex: 'taxPeriod',
      key: 'taxPeriod',
      render: (text) => text ?? '-',
    },
    {
      title: 'Filing Date',
      dataIndex: 'dateOfFiling',
      key: 'dateOfFiling',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
  ];

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb
          gstNumber={companiesData?.gstNumber ?? ''}
          id={(id || companiesData?._id) ?? ''}
        />
      </div>
      <div className="d-flex flex-vertical gap-16">
        <Text className="heading">Track Return Status</Text>
        <Card className="gap-16">
          <div className="track-return-status d-flex flex-vertical gap-16">
            <Form
              form={filterForm}
              layout="vertical"
              onValuesChange={() => setCurrentPage(1)}
              initialValues={initialFilters}
            >
              <div className="filters d-flex align-center gap-24">
                <Form.Item
                  name={['filters', 'companyId']}
                  label="Select Client"
                  className="select"
                >
                  <InfiniteSelect
                    query={COMPANIES}
                    variableSelector={({ skip, limit, search }) => ({
                      filter: {
                        skip,
                        limit,
                        search,
                        withTradeName: false,
                      },
                      sort: {
                        sortBy: SortOrder.Desc,
                      },
                    })}
                    dataSelector={({ companies }) => {
                      return (
                        companies?.data?.map((item) => {
                          const values =
                            [item?.gstNumber, item?.tradeName].join(' | ') ??
                            '';
                          return {
                            label: (
                              <Tooltip title={values} placement="top">
                                {values}
                              </Tooltip>
                            ),
                            value: item?._id ?? '',
                          };
                        }) ?? []
                      );
                    }}
                    countSelector={({ companies }) => companies?.count ?? 0}
                    allowSearch
                    onChange={(value) => {
                      if (!value) {
                        filterForm.setFieldsValue({
                          filters: {
                            financialYear: null,
                          },
                        });
                      }
                    }}
                    placeholder="GSTIN, Tradename"
                    allowClear
                    disabled={!!id}
                  />
                </Form.Item>
                <Form.Item
                  name={['filters', 'financialYear']}
                  label="Financial year"
                  className="select"
                >
                  <Select
                    disabled={!filter?.companyId}
                    suffixIcon={<SelectDropdownIcon />}
                    placeholder="Select Financial Year"
                    options={generateYearOptions(GST_START_YEAR)}
                    allowClear
                    showSearch
                  />
                </Form.Item>
              </div>
            </Form>
            {filter?.companyId ? (
              <LoaderComponent spinning={loading}>
                <div className="tables d-flex flex-vertical gap-24 full-width">
                  <div className="d-flex gap-16 full-width">
                    <div className="width-percent-50">
                      <Text>GSTR 1</Text>
                      <TableComponent
                        columns={columns}
                        dataSource={(data?.GSTR1 as TrackReturnStatus[]) || []}
                        locale={EMPTY_STATE}
                        scroll={{ y: 35 * 5 }}
                      />
                    </div>
                    <div className="width-percent-50">
                      <Text>GSTR 3B</Text>
                      <TableComponent
                        columns={columns}
                        dataSource={(data?.GSTR3B as TrackReturnStatus[]) || []}
                        locale={EMPTY_STATE}
                        scroll={{ y: 35 * 5 }}
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-16 full-width">
                    <div className="width-percent-50">
                      <Text>GSTR 9</Text>
                      <TableComponent
                        columns={columns}
                        dataSource={(data?.GSTR9 as TrackReturnStatus[]) || []}
                        locale={EMPTY_STATE}
                        scroll={{ y: 35 * 5 }}
                      />
                    </div>
                    <div className="width-percent-50">
                      <Text>GSTR 9C</Text>
                      <TableComponent
                        columns={columns}
                        locale={EMPTY_STATE}
                        dataSource={(data?.GSTR9C as TrackReturnStatus[]) || []}
                        scroll={{ y: 35 * 5 }}
                      />
                    </div>
                  </div>
                </div>
              </LoaderComponent>
            ) : (
              <Card>
                <Text className="d-flex justify-center m-40 dark-text">
                  {SELECT_CLIENT_MESSAGE}
                </Text>
              </Card>
            )}
          </div>
        </Card>
      </div>
      <div className="d-flex mt-16"></div>
    </div>
  );
};

export default TrackReturnsStatus;
