import { Tag, Tooltip } from 'antd';
import {
  ActivityLogStatus,
  AdditionalNoticeStatus,
  EProceedingStatus,
  NoticeAndOrderStatus,
  SyncLogStatus,
} from '../__generated__/graphql';
import { StatusTagProps } from '../types/common.type';

export enum GeneralStatus {
  Verified = 'Verified',
  ForVerification = 'For verification',
}

const STATUS_CLASS_MAP = new Map<string, string>([
  [GeneralStatus.Verified, 'status-form-verified'],
  [GeneralStatus.ForVerification, 'status-for-verification'],
  [ActivityLogStatus.Failed, 'status-failed'],
  [SyncLogStatus.Failed, 'status-failed'],
  [ActivityLogStatus.Success, 'status-success'],
  [SyncLogStatus.Success, 'status-success'],
  [EProceedingStatus.Pending, 'status-pending'],
  [EProceedingStatus.Closed, 'status-closed'],
  [EProceedingStatus.Replied, 'status-replied'],
  [AdditionalNoticeStatus.Closed, 'status-closed'],
  [AdditionalNoticeStatus.Open, 'status-replied'],
  [NoticeAndOrderStatus.Closed, 'status-closed'],
  [NoticeAndOrderStatus.Open, 'status-replied'],
]);

const getClassName = (status: string) => {
  return STATUS_CLASS_MAP.get(status) || 'status-default';
};

const StatusTag = ({ status }: StatusTagProps) => {
  const className = getClassName(status);

  return (
    <Tooltip title={status}>
      <Tag className={`${className} capitalize-first`} key={status}>
        {status}
      </Tag>
    </Tooltip>
  );
};

export default StatusTag;
