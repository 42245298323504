import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Flex,
  Popover,
  Row,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  AdditionalNoticeStatus,
  CalenderData,
  CompanyCalenderCountResponse,
  DurationType,
  EProceedingCalenderCountResponse,
  EProceedingStatus,
} from '../../__generated__/graphql';
import { GoogleCalendar, Outlook, ThreeDotIcon } from '../../assets/svg';
import axiosInstance from '../../common/axiosInstance';
import {
  CALENDAR_PAYLOAD_TYPE,
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  ROUTES,
} from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import { CURRENT_SYNC_CALENDAR } from '../../modules/direct-tax/dashboard/graphql/Queries';
import { EProceedingCalendarData } from '../../types/common.type';
import CommonCalendar from '../CommonCalendar';

const { Text } = Typography;

const RenderCalendarSyncButton = ({
  googleCalendarRedirectLink,
  outlookCalendarRedirectLink,
  calendarDisconnected,
}: {
  googleCalendarRedirectLink: string;
  outlookCalendarRedirectLink: string;
  currentUserCalendar: CalenderData;
  calendarDisconnected: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const {
    data: { currentUserCalender: currentUserCalendarData } = {},
    loading: currentSyncCalendarLoading,
  } = useQuery(CURRENT_SYNC_CALENDAR, {
    fetchPolicy: 'network-only',
  });

  if (currentSyncCalendarLoading) {
    return (
      <Spin
        spinning
        size="small"
        className="d-flex justify-center align-center flex-vertical"
      />
    );
  }

  if (currentUserCalendarData?.type === 'GOOGLE') {
    return (
      <div className="d-flex justify-between align-center full-width">
        <span className="d-flex align-center gap-2">
          <span className="d-flex pointer">
            <GoogleCalendar />
          </span>
          Google Calendar
        </span>
        <span className="d-flex">
          <Popover
            content={
              <span
                className="color-main disconnect-btn"
                onClick={() => {
                  calendarDisconnected();
                }}
              >
                Disconnect
              </span>
            }
            trigger="click"
            open={open}
            onOpenChange={() => setOpen((prev) => !prev)}
            placement="bottomRight"
          >
            <span className="pointer">
              <ThreeDotIcon />
            </span>
          </Popover>
        </span>
      </div>
    );
  }
  if (currentUserCalendarData?.type === 'OUTLOOK') {
    return (
      <div className="d-flex justify-between align-center full-width">
        <span className="d-flex align-center gap-2">
          <span className="d-flex pointer">
            <Outlook />
          </span>
          Outlook Calendar
        </span>
        <span className="d-flex">
          <Popover
            content={
              <span
                className="color-main disconnect-btn"
                onClick={() => {
                  calendarDisconnected();
                }}
              >
                Disconnect
              </span>
            }
            trigger="click"
            open={open}
            onOpenChange={() => setOpen((prev) => !prev)}
            placement="bottomRight"
          >
            <span className="pointer">
              <ThreeDotIcon />
            </span>
          </Popover>
        </span>
      </div>
    );
  }
  return (
    <div className="sync-calendar">
      <Text>Sync your Calendar</Text>
      <Flex gap={4}>
        <Button
          block
          icon={<GoogleCalendar />}
          className="d-flex align-center justify-center"
          onClick={async () => {
            const response = await axiosInstance.post(
              googleCalendarRedirectLink,
              {
                type:
                  location.pathname === `/${ROUTES.DIRECT_TAX}`
                    ? CALENDAR_PAYLOAD_TYPE.DIRECT_TAX
                    : CALENDAR_PAYLOAD_TYPE.INDIRECT_TAX,
              },
            );
            if (response?.data) {
              window.open(response.data?.message, '_self');
            }
          }}
        >
          Google
        </Button>

        <Button
          block
          icon={<Outlook />}
          className="d-flex align-center justify-center"
          onClick={async () => {
            const response = await axiosInstance.post(
              outlookCalendarRedirectLink,
              {
                type:
                  location.pathname === `/${ROUTES.DIRECT_TAX}`
                    ? CALENDAR_PAYLOAD_TYPE.DIRECT_TAX
                    : CALENDAR_PAYLOAD_TYPE.INDIRECT_TAX,
              },
            );
            if (response?.data) {
              window.open(response.data?.authUrl, '_self');
            }
          }}
        >
          Outlook
        </Button>
      </Flex>
    </div>
  );
};

const SyncCalendar = ({
  calendarCounts,
  getSelectedRange,
  googleCalendarRedirectLink,
  outlookCalendarRedirectLink,
  currentUserCalendar,
  currentUserCalendarLoading,
  calendarDisconnected,
  isGst,
}: {
  calendarCounts:
    | EProceedingCalenderCountResponse
    | CompanyCalenderCountResponse;
  getSelectedNotice: (notice: EProceedingCalendarData) => void;
  selectedDateResponses: EProceedingCalendarData;
  getSelectedRange: (dateString: [string, string]) => void;
  googleCalendarRedirectLink: string;
  outlookCalendarRedirectLink: string;
  currentUserCalendar: CalenderData;
  currentUserCalendarLoading: boolean;
  calendarDisconnected: () => void;
  isGst?: boolean;
}) => {
  const { navigate } = useRouter();
  const handleSelectedNotice = (notice: EProceedingCalendarData) => {
    if (notice?.date) {
      const selectedDate = dayjs(notice?.date, defaultDateFormat).format(
        defaultDateFormat,
      );
      const queryParams = {
        [COMMON_QUERY_PARAMS.STATUS]: isGst
          ? AdditionalNoticeStatus?.Open
          : EProceedingStatus.Pending,
        [COMMON_QUERY_PARAMS.DURATION]: DurationType.Custom,
        [COMMON_QUERY_PARAMS.START]: selectedDate,
        [COMMON_QUERY_PARAMS.END]: selectedDate,
        [COMMON_QUERY_PARAMS.IS_ISSUED_ON]: 'true',
      };
      navigate(
        location?.pathname?.includes(ROUTES?.INDIRECT_TAX)
          ? `${ROUTES?.NOTICE_ORDERS}?${new URLSearchParams(queryParams).toString()}`
          : `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(queryParams).toString()}`,
      );
    }
  };

  return (
    <Card className="mr-24">
      <CommonCalendar
        calendarCountList={calendarCounts}
        getSelectedNotice={handleSelectedNotice}
        getSelectedRange={getSelectedRange}
      />
      <Card className="mt-16 mb-16">
        <Row className="d-flex  align-self-stretch gap-16">
          {/* kept for future use */}
          {/* <Col className="d-flex flex-wrap align-center">
            <div className="color-box bg-blue" />
            <span className="ml-10 color-blue">
              {calendarCounts?.totalIssuedOn ?? 0} notice issued
            </span>
          </Col> */}
          <Col className="d-flex flex-wrap align-center">
            <div className="color-box bg-red" />
            <span className="ml-10 color-red">
              {calendarCounts?.totalResponseDueCount ?? 0} notice due
            </span>
          </Col>
          {/* kept for future use */}
          {/* <Col className="d-flex align-center">
            <div className="color-box bg-orange" />
            <span className="ml-10 color-orange">
              {calendarCounts?.totalIssuedOn ?? 0} notice issues and{' '}
              {calendarCounts?.totalResponseDueCount ?? 0} dues
            </span>
          </Col> */}
        </Row>
      </Card>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBorderColor: 'var(--textDark)',
              defaultColor: 'var(--textDark)',
              fontWeight: 500,
              defaultHoverColor: 'var(--textDark)',
              defaultHoverBorderColor: 'var(--textDark)',
            },
          },
        }}
      >
        {!currentUserCalendarLoading && (
          <RenderCalendarSyncButton
            currentUserCalendar={currentUserCalendar}
            googleCalendarRedirectLink={googleCalendarRedirectLink}
            outlookCalendarRedirectLink={outlookCalendarRedirectLink}
            calendarDisconnected={calendarDisconnected}
          />
        )}
      </ConfigProvider>
    </Card>
  );
};

export default SyncCalendar;
