import { Link } from 'react-router-dom';
import { Attachment } from '../../__generated__/graphql';
import { FilePDF } from '../../assets/svg';
import { UpdateModalType } from '../../types/common.type';
import CommonModal from './CommonModal';

const AttachmentModal = ({
  title,
  open,
  closable,
  onCancel,
  className,
  attachments,
}: UpdateModalType) => {
  return (
    <div className="attachments">
      <CommonModal
        title={title}
        open={open}
        footer={false}
        closable={closable}
        onCancel={onCancel}
        className={className}
        wrapClassName="attachment-modal"
      >
        <div className="d-flex gap-16 flex-vertical">
          {attachments?.map((file: Attachment, index: number) => (
            <div className="d-flex align-center" key={index}>
              <span className="mr-8 d-flex">
                <FilePDF />
              </span>
              <Link
                className="color-blue max-width-430"
                to={file?.url || ''}
                download
                target="_blank"
              >
                {file?.name}
              </Link>
            </div>
          ))}
        </div>
      </CommonModal>
    </div>
  );
};

export default AttachmentModal;
