import { Card, Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { GUTTER_VARIATIONS } from '../../../../common/constants';
import { ProfileDetailsCardProps } from '../../companies/graphql/clients.type';
import StatusTag from '../../../../components/CommonTag';

dayjs.extend(relativeTime);
const { Text } = Typography;

const ProfileDetailCard = ({
  title,
  caseId,
  gstInNumber,
  applicationDate,
  status,
}: ProfileDetailsCardProps) => {
  return (
    <Card className="mb-24 pl-8 pr-8">
      <Text className="heading">{title}</Text>
      <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">Case ID</Text>
          <Text className="value">{caseId}</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">GSTIN/UIN/Temporary ID</Text>
          <Text className="value">{gstInNumber}</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">Date of Application/Case Creation</Text>
          <Text className="value">{applicationDate}</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">Status</Text>
          <span>
            <StatusTag status={status} />
          </span>
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileDetailCard;
