import { useLazyQuery } from '@apollo/client';
import { Button, Card, Form, Select, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import {
  InputMaybe,
  SortOrder,
  SyncLogPortalType,
  SyncLogsFilter,
  SyncLogsResponse,
  SyncLogsSortOn,
} from '../../../__generated__/graphql';
import { SelectDropdownIcon } from '../../../assets/svg';
import {
  defaultDateTimeFormat,
  EMPTY_STATE,
  LIMIT,
  SELECT_CLIENT_MESSAGE,
  SYNC_STATUS,
} from '../../../common/constants';
import CommonPagination from '../../../components/CommonPagination';
import TableComponent from '../../../components/CommonTable';
import StatusTag from '../../../components/CommonTag';
import InfiniteSelect from '../../../components/InfiniteSelect';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import { COMPANY_LIST, SYNC_LOG } from './graphql/Queries';
import dayjs from 'dayjs';
import { SyncData } from './graphql/clients.type';
const { Text } = Typography;

const initialValue = {
  filters: { status: null },
};

const SyncLog = () => {
  const [filterForm] = Form?.useForm();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [syncData, setSyncData] = useState<SyncData>({ data: [], count: 0 });

  const [filterList, setFilterList] = useState<SyncLogsFilter>({
    limit: LIMIT,
    skip: 0,
    username: '',
    portal: SyncLogPortalType?.Gst,
  });

  const filters = Form.useWatch('filters', filterForm) ?? initialValue?.filters;

  const filter: InputMaybe<SyncLogsFilter> = useMemo(
    () => ({
      isActive: filters?.isActive && filters?.isActive === 'active',
      limit: LIMIT,
      skip: (currentPage - 1) * LIMIT,
      status: !filters?.username?.value ? null : filters?.status,
      portal: SyncLogPortalType?.Gst,
      username: filters?.username?.value ?? '',
    }),
    [filters, currentPage, filterList],
  );

  const [getCompanySyncLogs, { loading }] = useLazyQuery(SYNC_LOG, {
    onCompleted: (res) => {
      if (res?.syncLogs?.data) {
        setSyncData({
          data: res.syncLogs.data as SyncLogsResponse[],
          count: res.syncLogs.count ?? 0,
        });
      }
    },
    onError() {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (filters?.username) {
      getCompanySyncLogs({
        variables: {
          filter,
          sort: { sortBy: SortOrder?.Desc, sortOn: SyncLogsSortOn?.UpdatedAt },
        },
      });
    }
  }, [filters?.username, getCompanySyncLogs, filter]);

  const handlePagination = (current: number) => {
    setCurrentPage(current);
    setFilterList((prev) => ({ ...prev, skip: (current - 1) * LIMIT }));
  };

  const handleClear = () => {
    filterForm?.resetFields();
    setSyncData({ data: [], count: 0 });
    setCurrentPage(1);
  };

  const columns: ColumnType<SyncLogsResponse>[] = [
    {
      title: 'GSTIN',
      key: 'gstin',
      fixed: 'left',
      render: (record) => {
        return record?.company?.gstNumber ?? '-';
      },
    },
    {
      title: 'Trade Name',
      key: 'tradeName',
      render: (record) => {
        return record?.company?.tradeName ?? '-';
      },
    },
    {
      title: 'User Name',
      key: 'username',
      render: (record) => {
        return record?.company?.username ?? '-';
      },
    },
    {
      title: 'Last Download Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => {
        return date ? dayjs(date).format(defaultDateTimeFormat) : '-';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: 'Status Message',
      dataIndex: 'message',
      key: 'message',
      render: (text) => text ?? '-',
    },
  ];

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb />
      </div>
      <div className="d-flex justify-between">
        <Text className="heading">Sync Log</Text>
      </div>
      <Card className="mt-16">
        <Form
          form={filterForm}
          layout="vertical"
          initialValues={initialValue}
          onValuesChange={() => setCurrentPage(1)}
        >
          <div className="d-flex align-center gap-16">
            <div className="gap-24 d-flex align-center">
              <Form.Item
                name={['filters', 'username']}
                label="Select Client"
                className="select"
              >
                <InfiniteSelect
                  query={COMPANY_LIST}
                  variableSelector={({ skip, limit, search }) => ({
                    filter: {
                      skip,
                      limit,
                      search,
                      withTradeName: false,
                    },
                    sort: {
                      sortBy: SortOrder.Desc,
                    },
                  })}
                  dataSelector={({ companies }) => {
                    return (
                      companies?.data?.map((item) => {
                        const values =
                          [item?.gstNumber, item?.tradeName].join(' | ') ?? '';
                        return {
                          label: (
                            <Tooltip title={values} placement="top">
                              {values}
                            </Tooltip>
                          ),
                          value: item?.username ?? '',
                        };
                      }) ?? []
                    );
                  }}
                  countSelector={({ companies }) => companies?.count ?? 0}
                  allowSearch
                  placeholder="GST, Tradename"
                  allowClear
                  onChange={(value) => {
                    if (!value) {
                      filterForm?.setFieldsValue({
                        filters: {
                          status: null,
                        },
                      });
                      setSyncData({
                        data: [],
                        count: 0,
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'status']}
                label="Status"
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  title={
                    !filters?.username?.value
                      ? 'Select client to apply status filter'
                      : ''
                  }
                  placeholder="All"
                  options={SYNC_STATUS}
                  disabled={!filters?.username?.value}
                  allowClear
                  showSearch
                />
              </Form.Item>
            </div>
            <Form.Item className="d-flex align-end align-self-end">
              <Button
                type="link"
                onClick={handleClear}
                disabled={!(filters.username || filters.status)}
              >
                Clear All
              </Button>
            </Form.Item>
          </div>
        </Form>
        <TableComponent
          rowKey="_id"
          columns={columns}
          dataSource={syncData?.data}
          loading={loading}
          pagination={false}
          scroll={{ x: 'max-content' }}
          locale={
            filter.username
              ? EMPTY_STATE
              : {
                  emptyText: SELECT_CLIENT_MESSAGE,
                }
          }
        />
        {syncData?.count ? (
          <CommonPagination
            count={syncData?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
    </div>
  );
};

export default SyncLog;
